<template>
  <span
    class="label font-weight-bold label-lg label-inline label-light-success"
    style="width: max-content"
    >{{ getMerchantName(merchantId) }}</span
  >
</template>

<script>
export default {
  props: ['merchantId'],
  data() {
    return {};
  },
  methods: {
    getMerchantName(merchantId) {
      switch (merchantId) {
        case 1:
          return 'VF';
        case 2:
          return 'TGMC';
        default:
          return 'VF';
      }
    },
  },
};
</script>
