<template>
  <table
    class="table table-bordered table-vertical-center table-hover"
    v-if="detailProducts.length"
  >
    <thead>
      <tr class="bg-gray-400">
        <th class="headerTable">STT</th>
        <th class="name headerTable">Sản phẩm</th>
        <th class="name headerTable">Đơn vị tính</th>
        <th class="value headerTable">SL</th>
        <th class="value headerTable">Thuế suất GTGT</th>
        <th class="code headerTable text-center">Giá tiền</th>
        <th class="code headerTable text-center">
          C.khấu phân bổ
        </th>
        <th class="code headerTable text-center">Thành tiền</th>
        <th class="code headerTable">VAT</th>
      </tr>
    </thead>

    <template>
      <tbody
        v-for="(product, index) in detailProducts"
        :key="`${product.id}_${index}`"
      >
        <tr :class="{ 'bg-light': selectedVat(product) }" v-if="product">
          <td style="width: 1%">
            <div>
              <p>
                {{ Number(index) + 1 }}
              </p>
            </div>
          </td>
          <td class="input-padding" style="width: 40%">
            <div class="d-flex d-inline">
              <i
                v-if="product.billItemType"
                v-bind="bindingAttrByType(product)"
              ></i>
              <span class="m-0 font-size-sm font-weight-bold">
                ({{ product.productCode }})
              </span>
            </div>
            <div class="mt-1 mb-2">
              <p class="productName font-weight-bolder">
                {{ product.productName }}
              </p>
              <p class="font-size-sm">
                <span class="font-weight-bolder text-warning">Tên VAT: </span
                >{{
                  product.productNameHasGift
                    ? product.productNameHasGift
                    : product.productNameVat
                }}
                <i
                  v-if="!product.productNameVat"
                  v-b-tooltip.hover
                  title="Yêu cầu tên VAT"
                  class="fa fa-exclamation-triangle icon-color text-danger mr-3"
                ></i>
              </p>

              <div>
                <b-form-input
                  :disabled="!isModeAdd"
                  class="input-text-size"
                  size="sm"
                  trim
                  v-model="product.serial"
                  placeholder="Nhập mã serial"
                ></b-form-input>
              </div>

              <div
                v-if="product.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION"
              >
                <p v-if="product.note && product.note.trim()" class="text-note">
                  Ghi chú: {{ product.note }}
                </p>
              </div>
            </div>
          </td>
          <td class="input-padding" style="width: 8%">
            <span>{{ product.productUnit }}</span>
          </td>
          <td class="input-padding text-center" style="width: 5%">
            <span>{{ product.quantity }}</span>
          </td>
          <td class="input-padding" style="width: 5%; text-align: center">
            <span v-if="product.vatAmount !== null">{{ getVatName(product) }}</span>
            <i
              v-else
              v-b-tooltip.hover
              title="Không có thông tin VAT"
              class="fa fa-exclamation-triangle icon-color text-danger"
            ></i>
          </td>
          <td class="input-padding" style="width: 12%; text-align: right">
            <span>
              <span class="text-primary" v-if="product.billItemType === 3"
                >Giá chiết khấu</span
              >
              <br />
              {{ convertPrice(product.productPrice) }}
              <p
                v-b-tooltip.hover.bottom
                title="Chiết khấu sản phẩm"
                v-if="product.discountAmount"
              >
                <i
                  class="fa fa-arrow-down icon-color "
                  style="font-size: 12px; text-align: right"
                >
                </i
                >:
                <span class="font-weight-bolder text-warning">
                  {{ convertPrice(product.discountAmount) }}
                </span>
              </p>
            </span>
          </td>
          <td style="width: 12%; text-align: right">
            {{ convertPrice(product.allotmentDiscountAmount) }}
          </td>
          <td class="input-padding" style="width: 12%; text-align: right">
            <span>
              {{ totalPriceOfProduct(product) }}
            </span>
          </td>
          <td style="width: 5%">
            <span>
              <b-form-checkbox
                :disabled="!isModeAdd"
                v-if="!hasInvalidDatas(product)"
                v-model="product.isChecked"
                size="lg"
              ></b-form-checkbox>
              <i v-bind="getClassStatus(product)" v-b-tooltip.hover.bottom></i>
            </span>
          </td>
        </tr>
      </tbody>
    </template>
    <tfoot>
      <tr class="align-middle text-right icon-color font-weight-bolder">
        <td :colspan="3">
          Tổng
        </td>
        <td class="text-center">{{ sumQuantity }}</td>
        <td></td>
        <td>
          {{ sumTotalAmount }}
        </td>
        <td>
          <p class="mb-0">
            <i
              v-b-tooltip.hover
              title="Tổng chiết khấu"
              class="fa fa-angle-double-down text-danger icon-nm mr-2"
            ></i>
            {{ sumAllotmentDiscount }}
          </p>
        </td>
        <td>
          <p class="mb-0">
            <i
              v-b-tooltip.hover
              title="Tổng chuyển khoản"
              class="fas fa-money-bill text-success icon-nm mr-2"
              style="font-size: inherit"
            ></i>
            {{ sumBill }}
          </p>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import {
  BILL_ITEM_TYPE,
  AMOUNT_TYPE,
  PRODUCT_TYPE,
  VAT_AMOUNT
} from '@/utils/enum';
import sumBy from 'lodash/sumBy';

import { convertPrice, currencyMask } from '@/utils/common';
import { ONE_HUNDRED } from '@/utils/constants';

export default {
  props: {
    detailProducts: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    discountAmountBill: {
      type: Number,
      required: true,
      default() {
        return 0;
      }
    },
    isModeAdd: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  components: {},

  data() {
    return {
      mask: currencyMask,
      BILL_ITEM_TYPE,
      AMOUNT_TYPE,
      PRODUCT_TYPE,
      checked: false,
      selectedProduct: [],
      unSelectedProduct: [],
      productKCT: {}
    };
  },
  computed: {
    sumAllotmentDiscount() {
      const details = [...this.detailProducts];
      if (!details.length) return 0;
      const sumTotalAmount = sumBy(details, 'allotmentDiscountAmount');
      return convertPrice(sumTotalAmount);
    },
    sumBill() {
      const details = [...this.detailProducts];
      if (!details.length) return 0;
      const sumBill = sumBy(
        details,
        item => item.totalAmount - item.allotmentDiscountAmount
      );
      return convertPrice(sumBill);
    },
    sumTotalAmount() {
      const details = [...this.detailProducts];
      if (!details.length) return 0;
      const sumBill = sumBy(details, 'totalAmount');
      return convertPrice(sumBill);
    },
    sumQuantity() {
      const details = [...this.detailProducts];
      if (!details.length) return 0;
      const sumBill = sumBy(details, 'quantity');
      return convertPrice(sumBill);
    }
  },
  methods: {
    convertPrice,
    hasInvalidDatas({ invalidDatas = [] }) {
      return invalidDatas.length;
    },
    getClassStatus({ invalidDatas = [], push }) {
      if (this.hasInvalidDatas({ invalidDatas, push })) {
        return {
          title: 'Dữ liệu không hợp lệ để đẩy',
          class: 'fas fa-times-circle text-danger icon-size'
        };
      }
    },
    calculateDiscountAmount({
      productPrice,
      discountAmount,
      discountType,
      quantity = 1
    }) {
      if (discountType === AMOUNT_TYPE.PERCENT) {
        return productPrice * quantity * (discountAmount / ONE_HUNDRED);
      }
      return discountAmount;
    },
    getVatName(product) {
      if (product.vatAmount === VAT_AMOUNT.PERCENT_KCT)
        return 'KCT';
      return `${product.vatAmount}%`;
    },
    bindingAttrByType({ billItemType }) {
      const mappingType = {
        [BILL_ITEM_TYPE.PRODUCT_BONUS]: {
          title: 'Quà tặng',
          class: 'fas fa-gift mr-1 font-size-sm',
          style: 'color: #2e7d32; font-size: 1rem'
        },
        [BILL_ITEM_TYPE.PRODUCT_PROMTION]: {
          title: 'Sản phẩm bán kèm',
          class: 'fas fa-tags mr-2 font-size-sm text-dark-75'
        }
      };
      return mappingType[billItemType] || '';
    },
    selectedVat({ isChecked }) {
      return !!isChecked;
    },
    totalPriceOfProduct(product) {
      if (!product || !Object.keys(product)) return 0;

      const totalAmount = product.totalAmount || 0;
      const allotmentDiscountAmount = product.allotmentDiscountAmount || 0;

      const newAmount = totalAmount - allotmentDiscountAmount;

      return this.convertPrice(newAmount);
    }
  }
};
</script>

<style></style>
