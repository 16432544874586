<template>
  <div class="bills-popup-vat-invoice">
    <b-modal
      id="popup-vat-invoice"
      ref="popup-vat-invoice"
      hide-footer
      :title="titleModal"
      no-close-on-backdrop
      size="xl"
    >
      <b-overlay
        :show="showOverlay"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <div v-if="vatBill">
          <div class="mb-3">
            <span class="mr-5 font-weight-bolder">Tổng tiền từ hóa đơn:
              <span class="font-weight-bolder text-success">
                {{ convertPrice(vatBill.totalAmount) }}</span>
            </span>
            <span class="mr-5 font-weight-bolder">Tổng chiết khấu:
              <span class="text-warning">{{
                convertPrice(vatBill.discountValue)
              }}</span></span>
            <span class="mr-5 font-weight-bolder">Giá thu lại quà tặng:
              <span class="text-warning">{{
                convertPrice(repurchasePricePerBill)
              }}</span></span>
            <span class="mr-5 font-weight-bolder">Tiêu điểm:
              <span class="text-primary">{{
                convertPrice(vatBill.pointToMoney)
              }}</span>
            </span>
          </div>
          <b-row>
            <b-col cols="9">
              <BillProductVatTable
                :discountAmountBill="vatBill.discountAmount"
                :detailProducts.sync="vatBill.details"
                :isModeAdd="isModeAdd"
              />
            </b-col>
            <b-col cols="3">
              <div class="form-group">
                <BillVatInvoiceInfo
                  :isValidEmail.sync="validate.isValidEmail"
                  :isValidTaxCode.sync="validate.isValidTaxCode"
                  :isValidPhone.sync="validate.isValidPhone"
                  :vatInvoice="vatInvoice"
                  :vatBill="vatBill"
                  :isModeAdd="isModeAdd"
                  :key="vatBill.id"
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-overlay
          v-if="isModeAdd"
          :show="isCreating"
          rounded
          opacity="0.6"
          class="d-inline-block mr-3"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                variant="light"
                small
                type="grow"
              ></b-spinner>
            </div>
          </template>
          <b-button
            :disabled="!validVatInvoiceData"
            @click="onUpsertBillVat"
            style="width: 80px"
            variant="primary"
            size="sm"
          >
            <strong>{{ getActionName }}</strong>
          </b-button>
        </b-overlay>

        <b-button
          style="width: 80px"
          variant="secondary"
          size="sm"
          @click="hideModalVatInvoice"
        >
          <strong>Hủy</strong>
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import keyBy from 'lodash/keyBy';

import { EventBus } from '@/core/services/event-bus';
import ApiService from '@/core/services/api.service';

import { VAT_INVOICE_PUSH } from '@/utils/enum';
import {
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
  calculateDiscountAmount,
} from '@/utils/common';

import BillVatInvoiceInfo from '@/view/components/bills/BillVatInvoiceInfo.vue';
import BillProductVatTable from '@/view/components/bills/BillProductVatTable.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'BillVatInvoiceModal',
  components: {
    BillVatInvoiceInfo,
    BillProductVatTable,
  },
  data() {
    return {
      repurchasePricePerBill: 0,
      mode: null,
      showOverlay: false,
      titleModal: '',
      vatBill: null,
      isCreating: false,
      disabled: false,
      validate: {
        isValidEmail: false,
        isValidTaxCode: false,
        isValidPhone: false,
        isValidSerial: false,
      },
      vatInvoice: {},
      idempotenceKey: null,
    };
  },
  created() {},
  computed: {
    isModeAdd() {
      return this.mode && this.mode === 'add';
    },
    validVatInvoiceData() {
      const { isValidEmail, isValidTaxCode } = this.validate;
      const { billVatAddress, taxCode, billVatName } = this.vatInvoice;

      const isValidVatAddress = billVatAddress && billVatAddress.length > 0;
      const isValidBillVatNames = billVatName && billVatName.length > 0;
      const validTaxCode = taxCode && isValidTaxCode;

      if (this.vatInvoice.billVatType === 2) {
        const isValid =
          (!billVatAddress && !taxCode) ||
          (isValidVatAddress && validTaxCode) ||
          isValidVatAddress;
        return isValidEmail && isValid;
      }
      return (
        isValidEmail && validTaxCode && isValidVatAddress && isValidBillVatNames
      );
    },
    getActionName() {
      return this.isModeAdd ? 'Tạo mới' : 'Cập nhật';
    },
  },
  methods: {
    calculateDiscountAmount,
    convertPrice,
    makePushStatus({ isChecked }) {
      return isChecked
        ? VAT_INVOICE_PUSH.APPROVED
        : VAT_INVOICE_PUSH.UN_APPROVED;
    },
    mappingPushStatusBillVatDetails() {
      const details = [...this.vatBill.details];

      const newDetails = details
        .map((product) => ({
          ...product,
          id: this.isModeAdd ? null : product.id,
          push: this.makePushStatus(product),
        }))
        .filter((product) => product.push === VAT_INVOICE_PUSH.APPROVED);

      return newDetails;
    },
    async onUpsertBillVat() {
      if (this.isCreating) return;
      this.isCreating = true;

      if (!this.idempotenceKey) {
        this.idempotenceKey = uuidv4();
      }

      const convertedDetails = this.mappingPushStatusBillVatDetails();

      if (!convertedDetails || !convertedDetails.length) {
        makeToastFaile('Chọn ít nhât 1 sản phẩm để tạo hóa đơn đỏ.');
        this.isCreating = false;
        return;
      }

      const billVat = Object.assign({}, this.vatBill, this.vatInvoice, {
        details: convertedDetails,
      });

      try {
        const url = 'vat-bills';
        const method = this.isModeAdd ? 'post' : 'put';
        const res = await ApiService[method](url, billVat, this.idempotenceKey);
        this.$emit('handlerFetchListBill');
        makeToastSuccess(res.data.message);
        setTimeout(() => {
          this.hideModalVatInvoice();
        }, 500);
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      } finally {
        setTimeout(() => {
          this.isCreating = false;
        }, 500);
      }
    },
    async getBillVatById(billNumber) {
      if (!billNumber) {
        return;
      }

      const params = { billNumber };
      const url = 'vat-bills';
      const method = 'query';
      try {
        const getNewBillVatPromise = ApiService.get(`${url}/${billNumber}`);
        const getCreatedBillVatPromise = ApiService[method](url, { params });

        const [newBillVat, createdBillVat] = await Promise.all([
          getNewBillVatPromise,
          getCreatedBillVatPromise,
        ]);

        const newData = [...(newBillVat.data.data.details || [])];
        const createdData = [
          ...(createdBillVat.data.data.dataset[0].details || []),
        ];

        const createdDataKeyBy = keyBy(createdData, 'billItemId');

        const connectedStatusBillVat = newData.map((product) => {
          const push =
            createdDataKeyBy[product.billItemId]?.push ||
            VAT_INVOICE_PUSH.UN_APPROVED;

          const id = createdDataKeyBy[product.productId]?.id || product.id;
          const isChecked = push === VAT_INVOICE_PUSH.APPROVED;
          return {
            ...product,
            id: id,
            push: push,
            isChecked: isChecked,
          };
        });
        const vatInvoice = this.getVatInfo({
          ...createdBillVat.data.data.dataset[0],
        });

        return Object.assign({}, newBillVat.data.data, vatInvoice, {
          details: connectedStatusBillVat,
        });
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      }
    },
    async getNewBillVatByBillNumber(billNumber) {
      if (!billNumber) {
        return;
      }

      const url = 'vat-bills';
      const method = 'get';
      try {
        const res = await ApiService[method](`${url}/${billNumber}`);

        const billVat = res.data.data;

        const details = billVat.details || [];
        const convetedDetails = details.map((product) => ({
          ...product,
          isChecked: false,
        }));
        return Object.assign({}, billVat, { details: convetedDetails });
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      }
    },
    async getBillVat(billNumber) {
      if (this.showOverlay) return;
      this.showOverlay = true;
      this.vatInvoice = {};
      this.vatBill = {};
      try {
        const vatBillDB = this.isModeAdd
          ? await this.getNewBillVatByBillNumber(billNumber)
          : await this.getBillVatById(billNumber);
        this.vatInvoice = this.getVatInfo(vatBillDB);

        this.vatBill = Object.assign({}, vatBillDB);
      } finally {
        this.showOverlay = false;
      }
    },
    hideModalVatInvoice() {
      this.$refs['popup-vat-invoice'].hide();
    },
    showPopupVatInvoice({ bill, mode }) {
      this.mode = mode;
      if (this.mode) {
        const title = this.isModeAdd
          ? `Tạo hóa đơn đỏ với hóa đơn #${bill.billNumber}`
          : `Chi tiết hóa đơn đỏ #${bill.id}`;
        this.titleModal = title;
        this.$bvModal.show('popup-vat-invoice');

        this.getBillVat(bill.billNumber);
      }
    },
    getVatInfo(vatBillDB) {
      return {
        phone: vatBillDB.phone || '',
        taxCode: vatBillDB.taxCode || '',
        email: vatBillDB.email || '',
        billVatName: vatBillDB.billVatName,
        billVatAddress: vatBillDB.billVatAddress,
        billVatType: vatBillDB.billVatType || 1,
      };
    },
  },
  mounted() {
    EventBus.$on('popup-create-vat-invoice', this.showPopupVatInvoice);
  },
  beforeDestroy() {
    EventBus.$off('popup-create-vat-invoice', this.showPopupVatInvoice);
  },
};
</script>

<style lang="scss">
#popup-vat-invoice {
  .modal-dialog.modal-xl {
    max-width: 90vw;
  }
  .modal-header {
    position: sticky;
    top: 0;
    background-color: inherit;
    z-index: 1055;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
}
</style>
